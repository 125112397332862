import React from "react";
import { Box, Image } from "@chakra-ui/react";
import Logo from "../assets/logo.svg";

const Header: React.FC = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="72px"
      backgroundColor="black"
    >
      <Image src={Logo} alt="Logo-header" width="121px" height="31px" />
    </Box>
  );
};

export default Header;
