import { Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import IconSeatOff from "../../components/Icons/IconSeatOff";
import IconSeatOn from "../../components/Icons/IconSeatOn";

interface PassengerInfoProps {
  dataConfirm: Record<string, any> | null;
}

export default function PassengerInfo({ dataConfirm }: PassengerInfoProps) {
  return (
    <>
      <Flex
        p={{ base: "10px 20px", md: "40px 99px" }}
        gap="38px"
        flexWrap="wrap"
      >
        {dataConfirm &&
          dataConfirm.map((item: any, idx: number) => {
            return (
              <Flex gap="10px" position="relative" key={idx}>
                {item.is_noshow ? <IconSeatOff /> : <IconSeatOn />}

                <Heading
                  color="white"
                  fontSize={{ base: "xl", md: "2xl" }}
                  position="absolute"
                  top={{ base: "49%", md: "50%" }}
                  left={{ base: "23px", md: "27px" }}
                  transform="translateY(-50%)"
                >
                  {item.seat_number}
                </Heading>

                <Flex
                  direction="column"
                  marginRight={{ base: "15px", md: "30px" }}
                >
                  <Text
                    fontSize={{ base: "10px", md: "xs" }}
                    color={
                      item.is_noshow ? "cititrans.metallic.silver" : undefined
                    }
                  >
                    Nama Penumpang
                  </Text>
                  <Heading
                    size={{ base: "xs", md: "md" }}
                    margin="6px 0 10px 0"
                    color={
                      item.is_noshow ? "cititrans.metallic.silver" : undefined
                    }
                  >
                    {item.name ? item.name : "-"}
                  </Heading>
                  <Text
                    fontSize="8px"
                    color={
                      item.is_noshow ? "cititrans.metallic.silver" : undefined
                    }
                  >
                    {item.is_noshow ? "NO SHOW" : "VERIFIED"}
                  </Text>
                </Flex>

                <Flex direction="column">
                  <Text
                    fontSize={{ base: "10px", md: "xs" }}
                    color={
                      item.is_noshow ? "cititrans.metallic.silver" : undefined
                    }
                  >
                    Nomor HP
                  </Text>
                  <Heading
                    size={{ base: "xs", md: "md" }}
                    marginTop="6px"
                    color={
                      item.is_noshow ? "cititrans.metallic.silver" : undefined
                    }
                  >
                    {item.phone ? item.phone : "-"}
                  </Heading>
                </Flex>
              </Flex>
            );
          })}
      </Flex>
    </>
  );
}
