import React from "react";
import { Route, Routes } from "react-router-dom";

import Checkin from "../pages/Checkin";

export default function CheckinRoute() {
  return (
    <Routes>
      <Route path="/" element={<Checkin />} />
      {/* <Route path="/create" element={<Create />} />
      <Route path="/edit/:id" element={<Edit />} /> */}
    </Routes>
  );
}
