import React, { ReactNode } from "react";
import { Button as ButtonChakra, ButtonProps } from "@chakra-ui/react";

interface CustomButtonProps extends ButtonProps {
  children: ReactNode;
}

const Button: React.FC<CustomButtonProps> = ({ children, ...props }) => {
  return (
    <ButtonChakra
      w={{ base: "124px", md: "154px" }}
      h={{ base: "42px", md: "52px" }}
      {...props}
    >
      {children}
    </ButtonChakra>
  );
};

export default Button;
