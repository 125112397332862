import { AlertProps, createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { alertAnatomy } from "@chakra-ui/anatomy";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle((props: AlertProps) => {
  const { status } = props;

  const successBase = status === "success" && {
    container: {
      background: "#31C440",
      color: "#FFFFFF",
    },
  };

  const warningBase = status === "warning" && {
    container: {
      background: "yellow-light.50",
      color: "black",
    },
  };

  const errorBase = status === "error" && {
    container: {
      background: "#FF507A",
      color: "#FFFFFF",
    },
  };

  const infoBase = status === "info" && {
    container: {
      background: "primary-light.50",
      color: "primary.500",
    },
  };

  return {
    ...successBase,
    ...warningBase,
    ...errorBase,
    ...infoBase,
  };
});

export const alertTheme = defineMultiStyleConfig({ baseStyle });
