import {
  Box,
  CardBody,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Switch,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import React, { Dispatch, SetStateAction, useState } from "react";
import Button from "../../components/Button";
import IconAkun from "../../components/Icons/IconAkun";
import InputField from "../../components/InputField";
import IconPhone from "../../components/Icons/IconPhone";
import IconPhoneDisabled from "../../components/Icons/IconPhoneDisabled";
import IconAkunDisabled from "../../components/Icons/IconAkunDisabled";
import IconSeatOn from "../../components/Icons/IconSeatOn";
import IconSeatOff from "../../components/Icons/IconSeatOff";
import BookingInfo from "./BookingInfo";
import PassengerInfo from "./PassengerInfo";
import { postData } from "../../utils/api";
import BookingCode from "./BookingCode";
import Alerts from "../../components/Alert";

interface FormCheckinProps {
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  dataBooking: Record<string, any> | null;
  dataConfirm: Array<any>;
  setDataConfirm: Dispatch<SetStateAction<Array<any>>>;
}

interface FormValues {
  [key: string]: string;
}

export default function FormCheckin({
  dataBooking,
  activeStep,
  setActiveStep,
  dataConfirm,
  setDataConfirm,
}: FormCheckinProps) {
  const [checkboxStates, setCheckboxStates] = useState(
    Array(dataBooking?.seats.length).fill(true)
  );
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [isLoadingConfirm, setIsLoadingConfirm] = useState<boolean>(false);

  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)");

  const initialValues: FormValues = {};
  const validationSchema: Record<string, any> = {};

  if (dataBooking?.seats.length > 0) {
    dataBooking?.seats.forEach((_: any, i: number) => {
      initialValues[`name${i}`] = "";
      initialValues[`phone${i}`] = "";
      initialValues[`seat_number${i}`] = _.seat_number;
      initialValues[`checkbox${i}`] = _.is_noshow;

      if (!checkboxStates[i]) {
        validationSchema[`name${i}`] =
          Yup.string().required("Nama harus diisi");
        validationSchema[`phone${i}`] = Yup.string()
          .matches(/^\d+$/, "Nomor HP harus berupa angka")
          .required("Nomor HP harus diisi");
      }
    });
  }

  const validationSchemaObject = Yup.object().shape(validationSchema);

  const handleCheckInClick = (values: FormValues) => {
    const dataArray = [];

    let i = 0;
    while (values[`name${i}`] !== undefined) {
      dataArray.push({
        name: values[`name${i}`],
        phone: values[`phone${i}`],
        seat_number: values[`seat_number${i}`],
        is_noshow: !values[`checkbox${i}`],
      });
      i++;
    }

    setDataConfirm(dataArray);
    setActiveStep(2);
  };

  const handleClickConfirm = async () => {
    setIsLoadingConfirm(true);
    setShowAlert(false);
    setMessageAlert("");
    try {
      const payload = {
        id: dataBooking?.id,
        seats: dataConfirm.map((item) => ({
          name: item.name,
          phone: item.phone,
          seatNumber: item.seat_number,
        })),
      };

      await postData(`/self-checkin-counter`, payload);

      setActiveStep(3);
      setIsLoadingConfirm(false);
    } catch (error: any) {
      setMessageAlert(error?.response?.data?.message);
      setShowAlert(true);
      setIsLoadingConfirm(false);
    }
  };

  return (
    <CardBody p="0">
      <Box
        h={{ base: "auto", md: "465px" }}
        overflowY="auto"
        overflowX={"hidden"}
      >
        <Alerts
          status={"error"}
          message={messageAlert}
          show={showAlert}
          setShow={setShowAlert}
        />
        <BookingCode bookingId={dataBooking?.booking_id} />

        {activeStep === 2 && <PassengerInfo dataConfirm={dataConfirm} />}

        <BookingInfo dataBooking={dataBooking} />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemaObject}
          onSubmit={handleCheckInClick}
        >
          {(props) => (
            <>
              <Form>
                {activeStep !== 2 && (
                  <Box p="0px 24px 24px">
                    {dataBooking?.seats.length > 0 &&
                      dataBooking?.seats.map((item: any, i: number) => (
                        <Flex
                          key={i}
                          direction={"row"}
                          justifyContent="space-between"
                          alignItems="center"
                          marginBottom="28px"
                          gap={{ base: 3, md: 5 }}
                          position="relative"
                        >
                          {checkboxStates[i] ? <IconSeatOff /> : <IconSeatOn />}

                          <Heading
                            color="white"
                            fontSize={{ base: "xl", md: "2xl" }}
                            position="absolute"
                            top={{ base: "49%", md: "50%" }}
                            left={{ base: "23px", md: "27px" }}
                            transform="translateY(-50%)"
                          >
                            {item.seat_number}
                          </Heading>

                          <Flex
                            direction={{ base: "column", md: "row" }}
                            flex="1"
                            gap={{ base: 5, md: 5 }}
                          >
                            {!isLargerThanMobile && (
                              <FormControl display="flex" alignItems="center">
                                <FormLabel
                                  htmlFor="isChecked"
                                  fontSize="xs"
                                  mb="0"
                                >
                                  Hadir
                                </FormLabel>
                                <Switch
                                  colorScheme="cititrans.sunglow"
                                  id="isChecked"
                                  size="sm"
                                  isChecked={!!props.values[`checkbox${i}`]}
                                  onChange={() => {
                                    const updatedStates = [...checkboxStates];
                                    updatedStates[i] = !updatedStates[i];

                                    props.setFieldValue(
                                      `checkbox${i}`,
                                      !updatedStates[i]
                                    );
                                    setCheckboxStates(updatedStates);

                                    props.setFieldValue(`name${i}`, "");
                                    props.setFieldValue(`phone${i}`, "");
                                  }}
                                />
                              </FormControl>
                            )}

                            <InputField
                              name={`name${i}`}
                              label="Nama Penumpang"
                              icon={
                                checkboxStates[i] ? (
                                  <IconAkunDisabled />
                                ) : (
                                  <IconAkun />
                                )
                              }
                              disabled={checkboxStates[i]}
                            />

                            <InputField
                              name={`phone${i}`}
                              label="Nomor HP"
                              icon={
                                checkboxStates[i] ? (
                                  <IconPhoneDisabled />
                                ) : (
                                  <IconPhone />
                                )
                              }
                              disabled={checkboxStates[i]}
                            />
                          </Flex>

                          {isLargerThanMobile && (
                            <Checkbox
                              colorScheme="cititrans.sunglow"
                              isChecked={!!props.values[`checkbox${i}`]}
                              onChange={() => {
                                const updatedStates = [...checkboxStates];
                                updatedStates[i] = !updatedStates[i];

                                props.setFieldValue(
                                  `checkbox${i}`,
                                  !updatedStates[i]
                                );
                                setCheckboxStates(updatedStates);

                                props.setFieldValue(`name${i}`, "");
                                props.setFieldValue(`phone${i}`, "");
                              }}
                            >
                              <Text fontSize="xs">Hadir</Text>
                            </Checkbox>
                          )}
                        </Flex>
                      ))}
                  </Box>
                )}

                <Divider
                  colorScheme="cititrans.secondary.gray"
                  borderWidth="1px"
                />

                {props.errors.atLeastOneTrue && (
                  <Box color="red">{props.errors.atLeastOneTrue}</Box>
                )}

                <Flex justifyContent="center" gap="24px" p="12px 0 19px 0">
                  {dataBooking?.booking_status_id !== 5 ? (
                    <>
                      <Button
                        isLoading={isLoadingConfirm}
                        colorScheme="cititrans.sunglow"
                        color="black"
                        onClick={() => {
                          activeStep === 1
                            ? props.handleSubmit()
                            : handleClickConfirm();
                        }}
                      >
                        {activeStep === 2 ? "Konfirmasi" : "Check-in"}
                      </Button>
                      <Button
                        onClick={() => setActiveStep(activeStep === 2 ? 1 : 0)}
                        bg={"black"}
                        color={"white"}
                        _hover={{ bg: "cititrans.dark.grey" }}
                        isDisabled={isLoadingConfirm}
                      >
                        Batal
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => setActiveStep(0)}
                      bg={"black"}
                      color={"white"}
                      _hover={{ bg: "cititrans.dark.grey" }}
                    >
                      Kembali
                    </Button>
                  )}
                </Flex>
              </Form>
            </>
          )}
        </Formik>
      </Box>
    </CardBody>
  );
}
