// chakra-theme.tsx

import { extendTheme } from "@chakra-ui/react";
import { alertTheme } from "./components/Alert-Theme";

const theme = extendTheme({
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Montserrat, sans-serif",
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: "16px",
      },
    },
    Alert: alertTheme,
  },
  colors: {
    cititrans: {
      sunglow: {
        "50": "#FEFAE6",
        "100": "#FEF0B9",
        "200": "#FDE78C",
        "300": "#FCDD5F",
        "400": "#FBD332",
        "500": "#FECA30",
        "600": "#C8A104",
        "700": "#967903",
        "800": "#645102",
        "900": "#322801",
      },
      muteblack: {
        500: "#343434",
      },
      secondary: {
        grey: "#D8D8D8",
        light: {
          grey: "#F2F2F2",
        },
      },
      black: "#050504",
      dark: {
        grey: "#58585A",
      },
      metallic: {
        silver: "#BFC8CC",
      },
    },
    // Add more custom colors as needed
  },
});

export default theme;
