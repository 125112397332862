import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/700.css';

import * as React from "react";
import AppRoutes from "./routes";
import { BrowserRouter } from "react-router-dom";
import {ChakraProvider} from "@chakra-ui/react";
import theme from "./chakra-theme";

export const App = () => (
  <BrowserRouter>
    <ChakraProvider theme={theme}>
      <AppRoutes />
    </ChakraProvider>
  </BrowserRouter>
);
