import { Box, CardBody, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import React, { Dispatch, SetStateAction, useState } from "react";
import Button from "../../components/Button";
import Alert from "../../components/Alert";
import IconCheckGreen from "../../components/Icons/IconCheckGreen";
import BookingCode from "./BookingCode";
import BookingInfo from "./BookingInfo";
import PassengerInfo from "./PassengerInfo";

interface SuccessProps {
  dataConfirm: Array<any>;
  dataBooking: Record<string, any> | null;
  isNotif: boolean;
  setActiveStep: Dispatch<SetStateAction<number>>;
}

export default function Success({
  dataConfirm,
  dataBooking,
  isNotif,
  setActiveStep,
}: SuccessProps) {
  const [showAlert, setShowAlert] = useState(true);

  return (
    <CardBody padding={"0 0 19px 0"}>
      {isNotif ? (
        <Flex
          align="center"
          direction="column"
          justify="center"
          gap="5"
          mt="1.25rem"
        >
          <IconCheckGreen />
          <Box textAlign="center" p="0 10px">
            <Heading fontSize={{ base: "16px", md: "19px" }} lineHeight="22px">
              Check-in Berhasil
            </Heading>
            <Text
              fontWeight="300"
              fontSize={{ base: "16px", md: "19px" }}
              lineHeight="22px"
            >
              Selamat menikmati perjalanan Anda.
            </Text>
          </Box>

          <Divider colorScheme="cititrans.secondary.gray" borderWidth="1px" />

          <Flex justifyContent="center">
            <Button
              onClick={() => setActiveStep(0)}
              colorScheme="cititrans.sunglow"
              color="black"
            >
              Tutup
            </Button>
          </Flex>
        </Flex>
      ) : (
        <>
          <Alert
            status={"success"}
            message={"Anda sudah check-in"}
            show={showAlert}
            setShow={setShowAlert}
          />
          <BookingCode bookingId={dataBooking?.booking_id} />
          <PassengerInfo dataConfirm={dataConfirm} />
          <BookingInfo dataBooking={dataBooking} />
          <Flex justifyContent="center">
            <Button
              onClick={() => {
                setActiveStep(0);
                setShowAlert(false);
              }}
              bg={"black"}
              color={"white"}
              _hover={{ bg: "cititrans.dark.grey" }}
            >
              Kembali
            </Button>
          </Flex>
        </>
      )}
    </CardBody>
  );
}
