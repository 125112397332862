import React from "react";
import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
  Container,
} from "@chakra-ui/react";
import iconGuideCheck from "../assets/icons/icon-guide-check-yellow.svg";
import number1 from "../assets/icons/numbers/number1.svg";
import number2 from "../assets/icons/numbers/number2.svg";
import number3 from "../assets/icons/numbers/number3.svg";
import number4 from "../assets/icons/numbers/number4.svg";
export default function GuideCheckin() {
  return (
    <Box
      justifyContent="center"
      bgColor="cititrans.dark.grey"
      padding="42px 76px"
      w="360px"
      h="auto"
    >
      <VStack spacing="53px">
        <Heading size="sm" color="white" textAlign="center">
          Panduan
          <br />
          Check-in Mandiri
        </Heading>
        <Image
          src={iconGuideCheck}
          alt="icon-check"
          width="92px"
          height="72px"
        />

        <Box justifyContent="center">
          <VStack spacing="30px">
            <Flex>
              <Image src={number1} alt="bg-number" width="48px" height="37px" />

              <Container>
                <Heading size="xs" color="white" marginBottom="10px">
                  Persiapan dan Persyaratan
                </Heading>

                <Text fontSize="10px" color="white">
                  Pastikan Anda memiliki kode QR atau kode booking untuk
                  melakukan check-in mandiri.
                </Text>
              </Container>
            </Flex>

            <Flex>
              <Image src={number2} alt="bg-number" width="48px" height="37px" />

              <Container>
                <Heading size="xs" color="white" marginBottom="10px">
                  Check-in melaui Input Manual
                </Heading>

                <Text fontSize="10px" color="white">
                  Jika tidak memiliki QR code, gunakan pilihan ‘Input Manual’
                  untuk memasukkan kode booking dan nomor telepon.
                </Text>
              </Container>
            </Flex>

            <Flex>
              <Image src={number3} alt="bg-number" width="48px" height="37px" />

              <Container>
                <Heading size="xs" color="white" marginBottom="10px">
                  Masukkan Data Penumpang
                </Heading>

                <Text fontSize="10px" color="white">
                  Lengkapi informasi berupa nama dan nomor telepon setiap
                  penumpang.
                </Text>
              </Container>
            </Flex>

            <Flex>
              <Image src={number4} alt="bg-number" width="48px" height="37px" />

              <Container>
                <Heading size="xs" color="white" marginBottom="10px">
                  Konfirmasi
                  <br /> Check-in
                </Heading>

                <Text fontSize="10px" color="white">
                  Proses check-in dinyatakan berhasil setelah Anda menerima
                  konfirmasi.
                </Text>
              </Container>
            </Flex>
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
}
