import { Box, Image } from "@chakra-ui/react";
import React from "react";
import Logo from "../assets/logo-footer.svg";

export default function Footer() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="72px"
      backgroundColor="black"
    >
      <Image src={Logo} alt="Logo-footer" width="254px" height="46px" />
    </Box>
  );
}
