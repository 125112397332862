// BookingInfo.tsx
import React from "react";
import { Flex, Text, Heading } from "@chakra-ui/react";
import moment from "moment";
import IconAkun from "../../components/Icons/IconAkun";
import IconDate from "../../components/Icons/IconDate";
import IconPassenger from "../../components/Icons/IconPassenger";
import IconTime from "../../components/Icons/IconTime";

interface BookingInfoProps {
  dataBooking: Record<string, any> | null;
}

const BookingInfo: React.FC<BookingInfoProps> = ({ dataBooking }) => {
  return (
    <Flex
      p={{ base: "20px 30px", md: "40px 99px" }}
      direction={"column"}
      gap="26px"
      bg="cititrans.secondary.light.grey"
      marginBottom="24px"
    >
      <Flex direction="column" gap="6px">
        <Text fontSize="xs">Rute</Text>
        <Heading size={{ base: "xs", md: "md" }}>{dataBooking?.route}</Heading>
      </Flex>

      <Flex
        direction="row"
        wrap={"wrap"}
        gap={{ base: "20px", md: "40px" }}
        alignItems="center"
      >
        <Flex direction="column" gap="6px">
          <Text fontSize={{ base: "10px", md: "xs" }}>
            Tanggal Keberangkatan
          </Text>

          <Flex alignItems="center" gap="10px">
            <IconDate />
            <Heading fontSize={{ base: "xs", md: "sm" }}>
              {moment(dataBooking?.departure_date).format("DD MMMM YYYY")}
            </Heading>
          </Flex>
        </Flex>

        <Flex direction="column" gap="6px">
          <Text fontSize={{ base: "10px", md: "xs" }}>Waktu</Text>

          <Flex alignItems="center" gap="10px">
            <IconTime />
            <Heading fontSize={{ base: "xs", md: "sm" }}>
              {dataBooking?.departure_time.slice(0, 5)}
            </Heading>
          </Flex>
        </Flex>

        <Flex direction="column" gap="6px">
          <Text fontSize={{ base: "10px", md: "xs" }}>Jumlah Penumpang</Text>
          <Flex alignItems="center" gap="10px">
            <IconAkun />
            <Heading
              fontSize={{ base: "xs", md: "sm" }}
            >{`${dataBooking?.total_seat} orang`}</Heading>
          </Flex>
        </Flex>

        <Flex direction="column" gap="6px">
          <Text fontSize={{ base: "10px", md: "xs" }}>Kursi</Text>

          <Flex alignItems="center" gap="10px">
            <IconPassenger />
            <Heading fontSize={{ base: "xs", md: "sm" }}>
              {dataBooking?.seats
                .map((item: any) => item?.seat_number)
                .join(", ")}
            </Heading>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default BookingInfo;
