import React, { useState } from "react";
import {
  Input,
  FormErrorMessage,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Button,
} from "@chakra-ui/react";
import { useField } from "formik";

interface InputFieldProps {
  name: string;
  label: string;
  type?: string;
  icon?: React.ReactElement;
  disabled?: boolean;
  onDelete?: () => void;
}

const InputField: React.FC<InputFieldProps> = ({
  name,
  label,
  type = "text",
  icon,
  disabled,
  onDelete,
}) => {
  const [field, meta] = useField(name);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <FormControl
      isInvalid={!disabled && !!meta.error && meta.touched}
      position="relative"
    >
      <FormLabel
        htmlFor={name}
        position="absolute"
        transform={{
          base:
            isFocused || !!field.value
              ? "translateY(-20px)"
              : icon
              ? "translate(40px, 10px)"
              : "translateY(10px)",
          md:
            isFocused || !!field.value
              ? "translateY(-20px)"
              : icon
              ? "translate(40px, 10px)"
              : "translateY(10px)",
        }}
        fontSize={
          isFocused || !!field.value ? ["xs", "xs", "sm"] : ["sm", "sm  ", "md"]
        }
        color={isFocused ? "cititrans.sunglow.500" : "gray.500"}
        transition="transform 0.2s, font-size 0.2s, color 0.2s"
      >
        {label}
      </FormLabel>
      <InputGroup position="relative">
        {icon && <InputLeftElement pointerEvents="none" children={icon} />}

        <Input
          {...field}
          id={name}
          type={type}
          isDisabled={disabled}
          variant="flushed"
          borderColor={
            disabled ? "cititrans.metallic.silver" : "cititrans.sunglow.500"
          }
          focusBorderColor="cititrans.sunglow.500"
          onFocus={handleFocus}
          onBlur={handleBlur}
          pr={onDelete ? "4.5rem" : undefined}
        />
        {onDelete && (
          <InputRightElement width="4.5rem">
            <Button h="19px" size={{ base: "xs", md: "sm" }} onClick={onDelete}>
              Hapus
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage>{!disabled && meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export default InputField;
