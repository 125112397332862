import React, { FC } from "react";
import { Flex, Text, Heading } from "@chakra-ui/react";
import IconTicket from "../../components/Icons/IconTicket";

interface BookingCodeProps {
  bookingId: string;
}

const BookingCode: FC<BookingCodeProps> = ({ bookingId }) => {
  return (
    <Flex
      bg="cititrans.sunglow.500"
      gap="2px"
      direction="column"
      p={{ base: "10px 30px", md: "15px 99px" }}
    >
      <Flex alignItems="center" gap="4px">
        <IconTicket />
        <Text fontSize="8px">KODE BOOKING</Text>
      </Flex>
      <Heading fontSize="12px">{bookingId}</Heading>
    </Flex>
  );
};

export default BookingCode;
