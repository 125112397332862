import React, { useEffect, useState } from "react";
import { Box, Image, Flex } from "@chakra-ui/react";
import { getData } from "../utils/api";

interface Slide {
  id: number;
  image: string;
  title: string;
  expired: string;
}

// const SLIDES_INTERVAL_TIME = 5000;
const SYNC_BANNER_INTERNAL = 5 * 60 * 1000; // 5 minute
// const ANIMATION_DIRECTION = "right";
const SHOW_BANNER_LIMIT = 3;

export default function Carousel() {
  const [slides, setSlides] = useState<Slide[]>([]);
  // const [currentSlide, setCurrentSlide] = useState<number>(0);
  const slidesCount: number = slides.length;

  useEffect(() => {
    getBanner().then();

    const syncBanner = setInterval(() => {
      getBanner().then();
    }, SYNC_BANNER_INTERNAL);
    return () => clearInterval(syncBanner);
  }, []);

  const getBanner = async () => {
    try {
      const res = await getData(`/general/promo?limit=${SHOW_BANNER_LIMIT}`);

      const data = res.data.data.data;

      const updatedData = data.map((item: any) => ({
        ...item,
        image: encodeURI(item.image),
      }));

      setSlides(updatedData);
    } catch (error: any) {}
  };

  // const setSlide = (slide: number) => {
  //   setCurrentSlide(slide);
  // };

  const carouselStyle = {
    transition: "all .5s",
    // marginLeft: `-${currentSlide * 100}%`,
  };

  useEffect(() => {
    // const prevSlide = () => {
    //   setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
    // };
    //
    // const nextSlide = () => {
    //   setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
    // };
    //
    // const automatedSlide = setInterval(() => {
    //   ANIMATION_DIRECTION.toLowerCase() === "left" ? prevSlide() : nextSlide();
    // }, SLIDES_INTERVAL_TIME);
    // return () => clearInterval(automatedSlide);
  }, [slidesCount]);

  return (
    <>
      {slides.length > 0 &&
        slides.map((slide, sid) => (
          <Flex
            width="full"
            _dark={{
              bg: "red",
            }}
            padding={"0 0 30px"}
            alignItems="center"
            justifyContent="center"
            gap={"34px"}
          >
            <Flex width="full" overflow="hidden" position="relative">
              <Flex width="full" {...carouselStyle}>
                <Box
                  key={`slide-${sid}`}
                  boxSize="full"
                  boxShadow="md"
                  flex="none"
                >
                  <Image
                    src={slide?.image}
                    alt="carousel image"
                    boxSize="full"
                    objectFit="contain"
                    rounded={10}
                  />
                </Box>
              </Flex>
            </Flex>
          </Flex>
        ))}

      {/*<HStack justify="center" bottom="8px" w="full" mb="85px">*/}
      {/*  {Array.from({*/}
      {/*    length: slidesCount,*/}
      {/*  }).map((_, slide) => (*/}
      {/*    <Box*/}
      {/*      key={`dots-${slide}`}*/}
      {/*      cursor="pointer"*/}
      {/*      boxSize={["5px", null, "10px"]}*/}
      {/*      m="0 2px"*/}
      {/*      bg={currentSlide === slide ? "cititrans.sunglow.500" : "#E4E4E4"}*/}
      {/*      rounded="50%"*/}
      {/*      display="inline-block"*/}
      {/*      transition="background-color 0.6s ease"*/}
      {/*      _hover={{*/}
      {/*        bg: "cititrans.sunglow.300",*/}
      {/*      }}*/}
      {/*      onClick={() => setSlide(slide)}*/}
      {/*    ></Box>*/}
      {/*  ))}*/}
      {/*</HStack>*/}
    </>
  );
}
