import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Text,
  Grid,
  Image,
  Card,
  CardHeader,
  Heading,
  CardBody,
  Flex,
  SimpleGrid,
  Divider,
  Stepper,
  Step,
  StepIndicator,
  StepIcon,
  StepNumber,
  StepTitle,
  StepDescription,
  StepSeparator,
  StepStatus,
  Show,
  AlertStatus,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Header from "../../components/Header";
import GuideCheckin from "../../components/GuideCheckin";
import Car from "../../assets/car.svg";
import bgYellow from "../../assets/bg-yellow-citi.svg";
import Footer from "../../components/Footer";
import InputField from "../../components/InputField";
import Button from "../../components/Button";
import FormCheckin from "./FormCheckin";
import moment from "moment";
import { getData } from "../../utils/api";
import Success from "./Success";
import Alert from "../../components/Alert";
import Carousel from "../../components/Carousel";

const steps = [
  { title: "Check-in", description: "Isi data diri" },
  { title: "Konfirmasi", description: "Periksa data" },
  { title: "Selesai", description: "Check-in berhasil" },
];

interface FormValues {
  bookingId: string;
  phone: string;
}

const validationSchema = Yup.object().shape({
  bookingId: Yup.string().required("Nama harus diisi"),
  phone: Yup.string()
    .matches(/^\d+$/, "Nomor HP harus berupa angka")
    .required("Nomor HP harus diisi"),
});

const initialValues: FormValues = {
  bookingId: "",
  phone: "",
};

export default function CheckinPage() {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const [activeStep, setActiveStep] = useState(0);
  const [dataBooking, setDataBooking] = useState(null);
  const [isNotif, setIsNotif] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState<AlertStatus>("success");
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [time, setTime] = useState(moment());

  const [dataConfirm, setDataConfirm] = useState<
    Array<{
      name: string;
      phone: string;
      seat_number: number;
      is_noshow: boolean;
    }>
  >([]);

  useEffect(() => {
    // Scroll to the end of the container when the activeStep changes.
    if (activeStep === 3 && scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft =
        scrollContainerRef.current.scrollWidth;
    } else if (activeStep === 2 && scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft =
        scrollContainerRef.current.scrollWidth / 4;
    }
  }, [activeStep]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(moment());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleSubmit = async (values: FormValues) => {
    setShowAlert(false);
    setIsLoadingSearch(true);
    try {
      const params = {
        bookingId: values.bookingId,
        phoneNumber: values.phone,
      };
      const res = await getData(`/self-checkin-counter/booking`, params);
      const data = res.data.data;
      setDataBooking(data);
      setIsLoadingSearch(false);
      if (data.booking_status_id === 5) {
        setActiveStep(2);

        const newData = data.seats.map((item: any) => ({
          ...item,
          phone: item.phone_number,
        }));
        setDataConfirm(newData);
        setActiveStep(3);
        setIsNotif(false);
      } else {
        setActiveStep(1);
        setIsNotif(true);
      }
    } catch (error: any) {
      setShowAlert(true);
      setAlertStatus("error");
      setAlertMessage(error?.response?.data?.message);
      setIsLoadingSearch(false);
    }
  };

  const handleDelete = (fieldName: string, formik: any) => {
    formik.setFieldValue(fieldName, "");
  };
  return (
    <Flex minH="100vh">
      <Box minH="100%" flex="1" textAlign="left" fontSize="xl">
        <Header />
        <Grid p={"0 30px 56px"}>
          <Image
            src={bgYellow}
            alt="bg-yellow-citi"
            width="1113px"
            height={{ base: "100%", md: "860px" }}
            position="absolute"
            zIndex="-1"
            top={{ base: "-60px", md: "0" }}
            left={{ base: "-10px", md: "-130px" }}
          />

          <Flex
            alignItems={{ base: "left", md: "center" }}
            margin={"24px 0"}
            direction={{ base: "column", md: "row" }}
          >
            <Image src={Car} alt="Car-home" width="258px" height="158px" />
            <Text
              fontSize={{ base: "sm", md: "md" }}
              fontWeight="700"
              textAlign="left"
            >
              Selamat datang!
              <br /> Silakan check-in untuk memulai perjalanan
            </Text>
          </Flex>

          <Card size="lg" borderRadius="12px">
            <CardHeader
              borderTopRadius="12px"
              boxShadow="0px -4px 16px 0px rgba(0, 0, 0, 0.15)"
              p={{ base: "0 28px", md: "0 200px" }}
            >
              <Box p="14px 0" overflowX="auto" ref={scrollContainerRef}>
                {activeStep === 0 ? (
                  <Heading size={{ base: "xs", md: "sm" }} textAlign="center">
                    Check-in Mandiri
                  </Heading>
                ) : (
                  <Stepper
                    size={"sm"}
                    index={activeStep}
                    colorScheme="cititrans.sunglow"
                  >
                    {steps.map((step, index) => (
                      <Step key={index}>
                        <StepIndicator>
                          <StepStatus
                            complete={<StepIcon />}
                            incomplete={<StepNumber />}
                            active={<StepNumber />}
                          />
                        </StepIndicator>

                        <Box flexShrink="0">
                          <StepTitle>{step.title}</StepTitle>
                          <StepDescription>{step.description}</StepDescription>
                        </Box>

                        <StepSeparator />
                      </Step>
                    ))}
                  </Stepper>
                )}
              </Box>
            </CardHeader>

            <Alert
              status={alertStatus}
              message={alertMessage}
              show={showAlert}
              setShow={setShowAlert}
            />

            {activeStep === 0 ? (
              <CardBody>
                <Box
                  h={{ base: "auto", md: "490px" }}
                  overflowY="auto"
                  overflowX={"hidden"}
                >
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {(formik) => (
                      <Form>
                        <SimpleGrid
                          gap={10}
                          p={{ base: "30px 0", md: "40px 90px" }}
                        >
                          <InputField
                            name="bookingId"
                            label="Nomor Booking"
                            onDelete={() => handleDelete("bookingId", formik)}
                          />

                          <InputField
                            name="phone"
                            label="Nomor HP"
                            onDelete={() => handleDelete("phone", formik)}
                          />
                        </SimpleGrid>

                        <Divider
                          colorScheme="cititrans.secondary.gray"
                          borderWidth="1px"
                        />

                        <Flex
                          align="center"
                          direction="column"
                          justify="center"
                          gap="5"
                        >
                          <Button
                            mt={4}
                            w="10rem"
                            h="50px "
                            color="black"
                            colorScheme="cititrans.sunglow"
                            type="submit"
                            isLoading={isLoadingSearch}
                          >
                            Cari
                          </Button>
                        </Flex>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </CardBody>
            ) : activeStep === 3 ? (
              <Success
                dataBooking={dataBooking}
                dataConfirm={dataConfirm}
                isNotif={isNotif}
                setActiveStep={setActiveStep}
              />
            ) : (
              <FormCheckin
                dataBooking={dataBooking}
                dataConfirm={dataConfirm}
                setDataConfirm={setDataConfirm}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
            )}
          </Card>
        </Grid>
      </Box>

      {activeStep === 0 && (
        <Show above="xl">
          <GuideCheckin />
        </Show>
      )}

      <Show above="xl">
        <Box
          w="400px"
          h="auto"
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          bg={
            activeStep !== 0
              ? "cititrans.dark.grey"
              : "linear-gradient(180deg, rgba(242, 242, 242, 0.00) 0%, #F2F2F2 100%)"
          }
        >
          <Flex
            bg="cititrans.sunglow.500"
            alignItems="center"
            justifyContent="center"
            p="29px 0"
            gap="11px"
          >
            <Heading fontSize="12px">{time.format("LT")}</Heading>
            <Text fontSize="12px">{time.format("dddd, DD MMMM YYYY")}</Text>
          </Flex>
          <Box p={"0px 40px"} marginTop="20px" className="h-screen">
            <Heading
              color={activeStep !== 0 ? "white" : undefined}
              size="sm"
              textAlign="center"
              marginBottom={"40px"}
            >
              Nikmati banyak keuntungan, untuk berbagai pilihan perjalanan.
            </Heading>

            <Carousel />
          </Box>

          <Box alignItems="end">
            <Footer />
          </Box>
        </Box>
      </Show>
    </Flex>
  );
}
