import axios from "../configs";

export const API_URL = process.env.REACT_APP_HOST_DEV;

export function getData(path: string, params = {}, restConfig = {}) {
  return axios.get(`${API_URL}${path}`, {
    params,
    ...restConfig,
  });
}

export async function postData(path: string, payload = {}, restConfig = {}) {
  return await axios.post(`${API_URL}${path}`, payload, {
    ...restConfig,
  });
}

export async function putData(path: string, payload = {}) {
  return await axios.put(`${API_URL}${path}`, payload);
}

export async function deleteData(path: string) {
  return await axios.delete(`${API_URL}${path}`);
}
