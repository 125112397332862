import React, { Dispatch, FC, SetStateAction } from "react";
import {
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton,
  Flex,
} from "@chakra-ui/react";
import IconSuccess from "./Icons/IconSuccess";
import IconError from "./Icons/IconError";

type AlertStatus = "info" | "warning" | "success" | "error" | "loading";

interface AlertsProps {
  message: string;
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
  status: AlertStatus;
}

const Alerts: FC<AlertsProps> = ({
  status,
  message,
  show = false,
  setShow,
}) => {
  if (!show) {
    return <></>;
  }

  const SuccessIcon = status === "success" ? IconSuccess : undefined;
  const ErrorIcon = status === "error" ? IconError : undefined;
  return (
    <Alert
      status={status}
      alignItems="center"
      justifyContent="space-between"
      textAlign="left"
      p={{ base: "12px 30px", md: "12px 90px" }}
      variant="solid"
    >
      <Flex gap={{ base: "5px", md: "10px" }} alignItems={"center"}>
        {SuccessIcon && <AlertIcon as={SuccessIcon} />}
        {ErrorIcon && <AlertIcon as={ErrorIcon} />}
        <AlertDescription
          fontSize={{ base: "10px", md: "16px" }}
          fontWeight={"600"}
          lineHeight="14px"
        >
          {message}
        </AlertDescription>
      </Flex>
      <CloseButton onClick={() => setShow(false)} />
    </Alert>
  );
};

export default Alerts;
